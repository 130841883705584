<template> </template>

<script>
import { defineComponent } from "vue";
import store from "@/store/index";

export default defineComponent({
  name: "AutoLogout",
  components: {},
  props: {
    
    logoutTime: {
      required: true,
    },
  },
  data: function() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      lougoutTimer: null,
    };
  },
  mounted() {
    this.events.forEach(function(event) {
      document.addEventListener(event, this.resetTimer, true);
    }, this);

    this.setTimer();
  },
  methods: {
    setTimer: function() {
      this.lougoutTimer = setTimeout(this.logout, this.logoutTime * 60 * 1000); // n *  60 * 1000 for n minutes
    },
    logout: function() {      
      let initialUser = store.getters.currentUser;
      if (!initialUser.currentClinic.isAdmin) {
        let backdrops = document.querySelectorAll("#kt_body  div.modal-backdrop.fade.show");
        backdrops.forEach(backdrop=>{
          backdrop.remove();
        });
        console.log("logout");
        localStorage.removeItem("ClinicUserToken");
        this.$router.push("/pin");
      }
    },
    resetTimer: function() {      
      clearTimeout(this.lougoutTimer);
      this.setTimer();
    },
  },
});
</script>
