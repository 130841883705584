
import { defineComponent } from "vue";
import KTNotificationsMenu from "@/layout/header/partials/NotificationsMenu.vue";
import KTQuickLinksMenu from "@/layout/header/partials/QuickLinksMenu.vue";
import KTUserMenu from "@/layout/header/partials/UserMenu.vue";
import Avatar from "@/components/Avatar.vue";
import FullScreen from "@/components/FullScreen.vue";
import HardReload from "@/components/HardReload.vue";
import NoLoad from "@/components/NoLoad.vue";
import * as bootstrap from "bootstrap";
import ApiService from "@/core/services/ApiService";
import Modal from "@/components/Modal.vue";
import ContactUs from "@/components/ContactUs.vue"
export default defineComponent({
  name: "topbar",
  components: {
    KTNotificationsMenu,
    KTQuickLinksMenu,
    KTUserMenu,
    Avatar,
    FullScreen,
    HardReload,
    NoLoad,
    Modal,
    ContactUs
  },
  data() {
    return {
      loadNumber: "",
      showNoLoad: false,
      errorModal: null,
      //isOnline: true,
      //isStatusDisplayed: false,
    };
  },
/*       mounted() {
      var errorModalElement = document.getElementById("no_load");
      this.errorModal = new bootstrap.Modal(errorModalElement);
      if (errorModalElement) {
        var selfModal = this;
        errorModalElement.addEventListener("hidden.bs.modal", function (event) {
          selfModal.showNoLoad = false;
        });
      }
    }, */
  methods: {

    /*     async checkStatus(){
      try {
         let response= await ApiService.get("user/current").then((response) => 
         {
            return response.status >= 200 && response.status < 300;
         });
         return response;
      }
      //  // either true or false
       catch (err) {
        return false; // definitely offline
      }
    },  */

    async searchLoad(e) {
      e.preventDefault();
      console.log(this.loadNumber);

      let doesLoadExist = (
        await ApiService.get(`Load/validate/${this.loadNumber}`)
      ).data;
      if (!doesLoadExist) 
      {
        console.log("not found");
        this.noLoadFound();
      }
      else {
        this.$router.replace({
          path: "/load",
          query: { search: this.loadNumber },
        });
      }
    },

    noLoadFound() {
/*       let shouldDoSomething = this.errorModal !== null;
      if (shouldDoSomething) {
        console.log("got to show boostrap");
        (<bootstrap.Modal>this.errorModal).show();
      } */
      this.showNoLoad = true;
    },

    onSubmitCompleted(loadNumber, packageNumber) {
/*       if (this.errorModal) {
        (<bootstrap.Modal>this.errorModal).hide();
        
      } */

      this.showNoLoad = false;
    },
  },

  /*   mounted() {

    setInterval(async () => {
      let currentStatus = this.isOnline;
      const result = await this.checkStatus();
      if (result !== true && this.isStatusDisplayed !== true) {
        this.isOnline = false;
        this.isStatusDisplayed = true;
      }
      if (
        result == true &&
        currentStatus === true &&
        this.isStatusDisplayed == true
      ) {
        this.isStatusDisplayed = false;
      }

      else if (result == true && currentStatus !== true) {
        this.isOnline = true;
        this.isStatusDisplayed = true;
      }
      
    }, 30000);
  }, */
});
