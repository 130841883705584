import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  id: "keypad_modal",
  tabindex: "-1",
  "aria-hidden": "true"
}
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered modal-fullscreen" }
const _hoisted_3 = { class: "page d-flex flex-row flex-column-fluid" }
const _hoisted_4 = {
  id: "kt_wrapper",
  class: "d-flex flex-column flex-row-fluid wrapper"
}
const _hoisted_5 = {
  id: "kt_content",
  class: "content d-flex flex-column flex-column-fluid"
}
const _hoisted_6 = { class: "post d-flex flex-column-fluid" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AutoLogout = _resolveComponent("AutoLogout")!
  const _component_Keypad = _resolveComponent("Keypad")!
  const _component_SideMenu = _resolveComponent("SideMenu")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AutoLogout, { logoutTime: 5 }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.showKeypad)
          ? (_openBlock(), _createBlock(_component_Keypad, { key: 0 }))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.asideEnabled)
        ? (_openBlock(), _createBlock(_component_SideMenu, {
            key: 0,
            darkLogo: _ctx.themeDarkLogo,
            lightLogo: _ctx.themeLightLogo
          }, null, 8, ["darkLogo", "lightLogo"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Header, {
          title: _ctx.pageTitle,
          onLogin: _ctx.onlogin
        }, null, 8, ["title", "onLogin"]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", {
              class: _normalizeClass({
              'container-fluid': _ctx.contentWidthFluid,
              container: !_ctx.contentWidthFluid
            })
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ])
      ])
    ])
  ], 64))
}