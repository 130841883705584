
import { defineComponent } from "vue";
import Avatar from "@/components/Avatar.vue";

export default defineComponent({
  name: "NoLoad",
  props: {
    errorLoad: String,
  },
  components: {
    Avatar,
  },
  mounted() {
      console.log("got to popup");
  },

  emits: ["submitCompleted"],
  methods: {
    discard() {
      this.$emit("submitCompleted", null, null);
    },

  },
});
