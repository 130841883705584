
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  data() {
    return {
      pin: "" as string,
      incorrectPin: false,
    };
  },
  computed: {
    pinmask() {
      let mask = "";
      for (const c in this.pin as any) {
        mask = mask + "*";
      }
      return mask;
    },
  },
  watch: {
    async pin(newPin) {
      if (newPin.length === 4) {
        this.incorrectPin = false;
        try {
          const login = await ApiService.postData(
            "user/login",
            { pin: this.pin },
            {}
          );
          console.log("login");
          console.log(login);
          localStorage.setItem("ClinicUserToken", login.data);
          location.reload();
        } catch (e: any) {
          if (e.response) {
            this.pin = "";
            if (e.response.status === 460) {
              //means incorrect pin
              this.incorrectPin = true;
              this.pin = "";
            }
          }
          console.log(e.response);
        }
      }
    },
  },
  methods: {
    keyPress(number) {
      this.incorrectPin = false;
      this.pin = this.pin + number;
    },
    async go() {
      this.incorrectPin = false;
      try {
        const login = await ApiService.postData(
          "user/login",
          {
            pin: this.pin
          },
          {}
        );
        console.log("login");
        console.log(login);
        localStorage.setItem("ClinicUserToken", login.data);
        location.reload();
      } catch (e: any) {
        if (e.response) {
          if (e.response.status === 460) {
            //means incorrect pin
            this.incorrectPin = true;
            this.pin = "";
          }
        }
        console.log(e.response);
      }
    }
  }
});
