
import { defineComponent, computed } from "vue";
import SideMenu from "@/layout/aside/Aside.vue";
import Header from "@/layout/header/Header.vue";
import LayoutService from "@/core/services/LayoutService";
import { useStore } from "vuex";
import { Actions, Mutations} from "@/store/enums/StoreEnums";
import KTLoader from "@/components/Loader.vue";
import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo
} from "@/core/helpers/config";
import { mapMutations } from 'vuex';
import { useAuth0 } from "@/auth";
import ApiService from "@/core/services/ApiService";
import Keypad from "@/components/Keypad.vue";
import * as bootstrap from "bootstrap";
import { UserContext } from "@/auth/UserContext";
import AutoLogout from "@/components/AutoLogout.vue";

export default defineComponent({
  name: "Layout",
  data() {
    return {
      asideEnabled: asideEnabled,
      themeLightLogo: themeLightLogo,
      themeDarkLogo: themeDarkLogo,
      loaderLogo: loaderLogo,
      subheaderDisplay: subheaderDisplay,      
      modal: null, 
      showKeypad: false,
      }
  },
  components: {
    SideMenu,
    Header,    
    Keypad,
    AutoLogout
  },
   setup(){
    const store = useStore();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return store.getters.pageBreadcrumbPath;
    });    

    // initialize html element classes
    LayoutService.init();

    return {
      pageTitle: pageTitle,
      contentWidthFluid,
      loaderEnabled,
      breadcrumbs
    }
  }
});
